<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Detail Pendukung</h3>
          </div>
          <div class="panel-body">
            <div class="alert alert-secondary fade show">
              <table>
                <tr>
                  <td><strong>Nama Relawan</strong></td>
                  <td>:</td>
                  <td>{{ detail.full_name }}</td>
                </tr>
                <tr>
                  <td><strong>Tanggal Diinput</strong></td>
                  <td>:</td>
                  <td>{{ this.timeStampFormat(detail.created_at) }}</td>
                </tr>
              </table>
            </div>
            <div class="row">
              <div class="col-md-4" v-if="detail.ktp_pendukung">
                <img
                  :src="detail.ktp_pendukung"
                  alt="KTP Pendukung"
                  style="width: 100%; border-radius: 5px; overflow: hidden"
                />
              </div>
              <div
                class="col-md-8"
                :class="{ 'col-md-12': !detail.ktp_pendukung }"
              >
                <table class="table table-striped text-uppercase">
                  <tr>
                    <td style="width: 120px">NIK</td>
                    <td style="width: 5px">:</td>
                    <td>{{ detail.nik_pendukung }}</td>
                  </tr>

                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>{{ detail.nama_pendukung }}</td>
                  </tr>

                  <tr>
                    <td>Jenis Kelamin</td>
                    <td>:</td>
                    <td>{{ detail.jenkel_pendukung }}</td>
                  </tr>

                  <tr>
                    <td>Tanggal Lahir</td>
                    <td>:</td>
                    <td>
                      {{ this.indonesianFormat(detail.tgl_lahir_pendukung) }}
                    </td>
                  </tr>

                  <tr>
                    <td>Telp/HP</td>
                    <td>:</td>
                    <td>{{ detail.hp_pendukung }}</td>
                  </tr>

                  <tr>
                    <td>Pekerjaan</td>
                    <td>:</td>
                    <td>{{ detail.pekerjaan }}</td>
                  </tr>

                  <tr>
                    <td>Agama</td>
                    <td>:</td>
                    <td>{{ detail.agama_pendukung }}</td>
                  </tr>

                  <tr>
                    <td>Kabupaten/Kota</td>
                    <td>:</td>
                    <td>{{ detail.nama_kab }}</td>
                  </tr>

                  <tr>
                    <td>Kecamatan</td>
                    <td>:</td>
                    <td>{{ detail.nama_kec }}</td>
                  </tr>

                  <tr>
                    <td>Desa/Kelurahan</td>
                    <td>:</td>
                    <td>{{ detail.nama_kel }}</td>
                  </tr>

                  <tr>
                    <td>RT/Dusun</td>
                    <td>:</td>
                    <td>
                      {{ detail.rt_pendukung }} /
                      {{ detail.rw_pendukung }}
                    </td>
                  </tr>

                  <tr>
                    <td>TPS</td>
                    <td>:</td>
                    <td>{{ detail.tps_pendukung }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      detail: {
        id_pendukung: "",
        nik_pendukung: "",
        nama_pendukung: "",
        tgl_lahir_pendukung: "",
        jenkel_pendukung: "",
        alamat_pendukung: "",
        kab_pendukung: "",
        kec_pendukung: "",
        kel_pendukung: "",
        rt_pendukung: "",
        rw_pendukung: "",
        tps_pendukung: "",
        hp_pendukung: "",
        agama_pendukung: "",
        usia_pendukung: "",
        pekerjaan: "",
        nama_lengkap: "",
        created_at: "",
        updated_at: "",
      },
      loading: false,
      showUserAccess: true,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.detail.id_pendukung = id;
      this.fetchData();
    } else {
      this.detail.id = "";
    }
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/pendukung/detail/" + this.detail.id_pendukung)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>
